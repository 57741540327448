@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}
html {
  scroll-behavior: smooth;
}

@layer components {
  .nav-link {
    @apply rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-primary-500 hover:text-white;
  }

  .nav-link.active {
    @apply bg-primary-500 text-white;
  }
}
